<template>
  <div class="question_page">
    <titleComponent title="常见问题解答" titleEnglish="QUESTION & ANSWER" />
    <div class="top">
      <el-autocomplete
        class="inline-input"
        v-model="selectValue"
        :fetch-suggestions="querySearch"
        placeholder="请输入内容"
        value-key="question"
        :trigger-on-focus="false"
        @select="handleSelect"
      ></el-autocomplete>
      <div class="search" @click="handleSelect">搜索</div>
    </div>
    <div class="question_block">
      <div class="answer_block" v-for="item in list" :key="item.id">
        <div class="q">
          <span>{{ item.question }}</span>
        </div>
        <div class="answer">
          <span class="atit">A：</span>
          <div class="cont" v-html="item.answer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleComponent from '@/components/home/title.vue'
export default {
  name: 'Guide',
  components: {
    titleComponent,
  },
  data() {
    return {
      selectValue: '',
      list: [],
    }
  },
  created() {},
  async mounted() {
    let res = this.api.home.qaListSearch()
    this.list = res.data
  },
  methods: {
    async querySearch(queryString, cb) {
      let data = {
        question: queryString,
      }
      let res = await this.api.home.qaListSearch(data)
      let results = []
      if (res.data) {
        results = res.data.filter((item) => {
          return item.question
        })
      }
      cb(results)
    },
    async handleSelect() {
      let data = {
        question: this.selectValue,
      }
      let res = await this.api.home.qaListSearch(data)
      this.list = res.data
    },
  },
}
</script>

<style lang="scss" scoped>
.question_page {
  width: 70%;
  margin: 0 auto;
  padding: 50px 0 0 0;
  .top {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    margin-top: 50px;
    background: #eee;
    box-sizing: border-box;
    border-bottom: solid 4px #cc1b31;
    padding: 5px 0;
    @include flex();
    .inline-input {
      width: 500px;
    }
    .search {
      width: 180px;
      height: 50px;
      border: 0;
      background: #cc1b31;
      font-size: 20px;
      color: #fff;
      line-height: 50px;
    }
  }
  .question_block {
    width: 100%;
    height: 500px;
    overflow: auto;
    margin-top: 20px;
    .answer_block {
      @include flex(column, flex-start, flex-start);
      margin-bottom: 40px;
      .q {
        span {
          display: inline-block;
          line-height: 38px;
          background: #cc1b31;
          font-size: 24px;
          padding: 0 20px;
          color: #fff;
          text-align: left;
          border-radius: 5px;
        }
      }
      .answer {
        display: flex;
        padding-left: 56px;
        line-height: 30px;
        font-size: 18px;
        color: #555;
        margin-top: 10px;
        .atit {
          color: #cc1b31;
          font-size: 24px;
        }
        .cont{
          text-align: left;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.question_page {
  .el-input__inner {
    border-radius: 0;
    height: 50px;
  }
  .el-input__inner:focus {
    border-color: #c0c4cc;
  }
}
</style>
